<template>
  <div>
    <Navbar @lang="getLang" color="white" style="z-index: 99999;" />
    <div class="factory-container">
      <div class="factory-content">
        <Backdrop :lang="lang" :contentBackdrop="contentBackdrop" />

        <CaseContent :lang="lang" :contentBackdrop="contentBackdrop"/>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import Backdrop from '@/components/CaseStudy/Backdrop.vue'
import CaseContent from '@/components/CaseStudy/CaseContent.vue'

export default {
  components: {
    Navbar,
    Footer,
    Backdrop,
    CaseContent,
    lang: ''
  },
  data: function () {
    return {
      contentBackdrop: '21factory',
      lang: ''
    }
  },
  methods: {
    getLang (value) {
      this.lang = value
    }
  }
}
</script>

<style scoped>
.factory-container {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  height: auto;
}

.factory-content {
  width: 100vw;
  min-height: 100vh;
  padding-top: 3rem;
}
</style>
